<template>
    <div class="signature_section" v-if="modelValue">
        <Form @submit="handleSubmit" :validation-schema="schema" v-slot="{ errors }" class="setting_wpr">
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Signature Name (internal use only)</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.signature_name }">
                        <Field autocomplete="off" name="signature_name" v-model="form.signature_name" type="text" :placeholder="placeholder.signature_name" />
                    </div>
                    <ErrorMessage name="signature_name" class="text-danger" />
                </div>
            </div>
            <div class="capsule_elm">
                <h5>Brand Bar</h5>
                <label :for="`brand-${uuid}`" class="switch_option capsule_btn">
                    <input type="checkbox" :true-value="1" :false-value="0" v-model="brandBar" :id="`brand-${uuid}`" hidden>
                    <div><span></span></div>
                </label>
            </div>
            <div v-if="brandBar" class="edit_section mb-4">
                <div class="section_title mb-1 mt-1">Brand Bar</div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Full Name</label>
                        <div class="field_wpr">
                            <Field autocomplete="off" type="text" name="full_name" v-model="form.full_name" :placeholder="placeholder.full_name" />
                        </div>
                    </div>
                    <div class="group_item">
                        <label class="input_label">Credentials</label>
                        <div class="field_wpr">
                            <Field autocomplete="off" type="text" name="credentials" v-model="form.credentials" :placeholder="placeholder.credentials" />
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Company Name</label>
                        <div class="field_wpr">
                            <Field autocomplete="off" type="text" name="company_name" v-model="form.company_name" :placeholder="placeholder.company_name" />
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Top Margin</label>
                        <div class="field_wpr m-0">
                            <Field autocomplete="off" type="number" name="top_margin" min="0" max="100" placeholder="10" v-model="form.top_margin" />
                        </div>
                    </div>
                    <div class="group_item">
                        <label class="input_label">Bottom Margin</label>
                        <div class="field_wpr m-0">
                            <Field autocomplete="off" type="number" name="bottom_margin" min="0" max="100" placeholder="10" v-model="form.bottom_margin" />
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Left Margin</label>
                        <div class="field_wpr m-0">
                            <Field autocomplete="off" type="number" name="left_margin" min="0" max="100" placeholder="10" v-model="form.left_margin" />
                        </div>
                    </div>
                    <div class="group_item">
                        <label class="input_label">Right Margin</label>
                        <div class="field_wpr m-0">
                            <input type="number" name="right_margin" min="0" max="100" placeholder="10" v-model="form.right_margin" />
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Top Padding</label>
                        <div class="field_wpr m-0">
                            <Field autocomplete="off" type="number" name="top_padding" v-model="form.top_padding" placeholder="10" min="10" max="300" />
                        </div>
                    </div>
                    <div class="group_item">
                        <label class="input_label">Bottom Padding</label>
                        <div class="field_wpr m-0">
                            <Field autocomplete="off" type="number" name="bottom_padding" v-model="form.bottom_padding" placeholder="10" min="10" max="300" />
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Left Padding</label>
                        <div class="field_wpr m-0">
                            <Field autocomplete="off" type="number" name="left_padding" min="0" max="100" placeholder="30" v-model="form.left_padding" />
                        </div>
                    </div>
                    <div class="group_item">
                        <label class="input_label">Right Padding</label>
                        <div class="field_wpr m-0">
                            <Field autocomplete="off" type="number" name="right_padding" min="0" max="100" placeholder="30" v-model="form.right_padding" />
                        </div>
                    </div>
                </div>
                <div class="form_grp mb-2">
                    <div class="group_item">
                        <label class="input_label">Corner Radius</label>
                        <div class="field_wpr m-0">
                            <Field autocomplete="off" type="number" name="border_radius" v-model="form.border_radius" placeholder="10" min="1" max="50" />
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Bar Color <small class="pointer" @click="resetToDefaultBarColor()">Reset to default color</small></label>
                        <color-picker v-model="form.bar_color" />
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Bar Text Color</label>
                        <color-picker v-model="form.bar_text_color" />
                    </div>
                </div>
            </div>
            <div class="capsule_elm">
                <h5>Bio</h5>
                <label :for="`bio-${uuid}`" class="switch_option capsule_btn">
                    <input type="checkbox" :true-value="1" :false-value="0" v-model="bio" :id="`bio-${uuid}`" hidden>
                    <div><span></span></div>
                </label>
            </div>
            <div v-if="bio" class="edit_section mb-4">
                <div class="section_title mb-1 mt-1">Brand Bio</div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Slogan</label>
                        <div class="field_wpr">
                            <Field autocomplete="off" type="text" name="slogan" v-model="form.slogan" :placeholder="placeholder.slogan" />
                        </div>
                    </div>
                </div>
                <div class="upload_image">
                    <image-library v-model="form.author_image" image-type="coach-logo" :is-avatar="true" />
                </div>
                <div class="mb-4">
                    <div class="capsule_elm">
                        <h5>Phone Number</h5>
                        <label for="showphone" class="switch_option capsule_btn p-0">
                            <input type="checkbox" :true-value="1" :false-value="0" v-model="form.has_phone" id="showphone" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div v-if="form.has_phone">
                        <div class="form_grp pt-2">
                            <div class="group_item">
                                <div class="field_wpr has_prefix">
                                    <input type="text" v-model="form.phone" :placeholder="placeholder.phone" ref="phone" />
                                    <span class="prefix"><i class="fas fa-phone-alt"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="capsule_elm">
                        <h5>E-mail</h5>
                        <label for="showemail" class="switch_option capsule_btn p-0">
                            <input type="checkbox" :true-value="1" :false-value="0" v-model="form.has_email" id="showemail" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div v-if="form.has_email">
                        <div class="form_grp pt-2">
                            <div class="group_item">
                                <div class="field_wpr has_prefix" :class="{ 'has-error': errors.email }">
                                    <Field autocomplete="off" type="email" name="email" v-model="form.email" :placeholder="placeholder.email" />
                                    <span class="prefix"><i class="fas fa-envelope"></i></span>
                                </div>
                                <ErrorMessage name="email" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="edit_section mb-4">
                    <div class="capsule_elm">
                        <h5>Address</h5>
                        <label for="showaddress" class="switch_option capsule_btn p-0">
                            <input type="checkbox" :true-value="1" :false-value="0" v-model="form.has_address" id="showaddress" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div v-if="form.has_address">
                        <div class="form_grp pt-2">
                            <div class="group_item">
                                <div class="field_wpr" :class="{ 'has-error': errors.address }">
                                    <Field autocomplete="off" type="text" name="address" v-model="form.address" :placeholder="placeholder.address" />
                                </div>
                                <ErrorMessage name="address" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="edit_section mb-4">
                    <div class="capsule_elm">
                        <h5>Website</h5>
                        <label for="showwebsite" class="switch_option capsule_btn p-0">
                            <input type="checkbox" :true-value="1" :false-value="0" v-model="form.has_website" id="showwebsite" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div v-if="form.has_website">
                        <div class="form_grp pt-2">
                            <div class="group_item">
                                <div class="field_wpr has_prefix" :class="{ 'has-error': errors.website }">
                                    <Field autocomplete="off" type="text" name="website" v-model="form.website" :placeholder="placeholder.website" />
                                    <span class="prefix">URL</span>
                                </div>
                                <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                <ErrorMessage name="website" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Abbreviation Text Color <small class="pointer" @click="resetToDefaultIconColor()">Reset to default color</small></label>
                        <color-picker v-model="form.icon_color" />
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Link Color</label>
                        <color-picker v-model="form.link_color" />
                    </div>
                </div>
                <div class="capsule_elm">
                    <h5>Abbreviate</h5>
                    <label for="abbreviate" class="switch_option capsule_btn p-0 border-0 mt-5 mb-2">
                        <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_abbreviate" name="is_abbreviate" id="abbreviate" hidden>
                        <div><span></span></div>
                    </label>
                </div>
            </div>
            <div class="capsule_elm">
                <h5>Social</h5>
                <label :for="`social-${uuid}`" class="switch_option capsule_btn">
                    <input type="checkbox" :true-value="1" :false-value="0" v-model="social" :id="`social-${uuid}`" hidden>
                    <div><span></span></div>
                </label>
            </div>
            <div v-if="social" class="edit_section mb-4">
                <div class="form_grp mb-2">
                    <div class="group_item">
                        <label class="input_label">Top Margin</label>
                        <div class="field_wpr m-0">
                            <Field autocomplete="off" type="number" name="social_top_margin" min="0" max="100" placeholder="10" v-model="form.social_mt" />
                        </div>
                    </div>
                    <div class="group_item">
                        <label class="input_label">Bottom Margin</label>
                        <div class="field_wpr m-0">
                            <Field autocomplete="off" type="number" name="social_bottom_margin" min="0" max="100" placeholder="10" v-model="form.social_mb" />
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="capsule_elm">
                        <h5>Show Facebook</h5>
                        <label for="showfacebook" class="switch_option capsule_btn p-0">
                            <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_facebook" name="is_facebook" id="showfacebook" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div v-if="form.is_facebook" class="mb-2">
                        <div class="form_grp pt-2 mb-2">
                            <div class="group_item">
                                <div class="field_wpr" :class="{ 'has-error': errors.facebook_url }">
                                    <Field autocomplete="off" type="text" name="facebook_url" v-model="form.facebook_url" placeholder="https://www.facebook.com/onboardme" />
                                </div>
                                <ErrorMessage name="facebook_url" class="text-danger" />
                            </div>
                        </div>
                        <div class="capsule_elm">
                            <h5>Use Default Facebook Icon</h5>
                            <label for="fbicon" class="switch_option capsule_btn p-0">
                                <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_facebook_icon" name="is_facebook_icon" id="fbicon" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div v-if="!form.is_facebook_icon" class="upload_image social_icon mt-2">
                            <image-library v-model="form.facebook_icon" image-type="favicon" :is-avatar="false" />
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="capsule_elm">
                        <h5>Show Instagram</h5>
                        <label for="showinstagram" class="switch_option capsule_btn p-0">
                            <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_ig" id="showinstagram" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div v-if="form.is_ig" class="mb-2">
                        <div class="form_grp pt-2 mb-2">
                            <div class="group_item">
                                <div class="field_wpr" :class="{ 'has-error': errors.ig_url }">
                                    <Field autocomplete="off" type="text" name="ig_url" v-model="form.ig_url" placeholder="https://www.instagram.com/onboardme" />
                                </div>
                                <ErrorMessage name="ig_url" class="text-danger" />
                            </div>
                        </div>
                        <div class="capsule_elm">
                            <h5>Use Default Instagram Icon</h5>
                            <label for="instaicon" class="switch_option capsule_btn">
                                <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_ig_icon" id="instaicon" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div v-if="!form.is_ig_icon" class="upload_image mt-2">
                            <image-library v-model="form.ig_icon" image-type="favicon" :is-avatar="false" />
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="capsule_elm">
                        <h5>Show Twitter</h5>
                        <label for="showtwitter" class="switch_option capsule_btn p-0">
                            <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_twitter" id="showtwitter" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div v-if="form.is_twitter">
                        <div class="form_grp pt-2 mb-2">
                            <div class="group_item">
                                <div class="field_wpr" :class="{ 'has-error': errors.twitter_url }">
                                    <Field autocomplete="off" type="text" name="twitter_url" v-model="form.twitter_url" placeholder="https://www.twitter.com/onboardme" />
                                </div>
                                <ErrorMessage name="twitter_url" class="text-danger" />
                            </div>
                        </div>
                        <div class="capsule_elm">
                            <h5>Use Default Twitter Icon</h5>
                            <label for="twittericon" class="switch_option capsule_btn p-0">
                                <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_twitter_icon" id="twittericon" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div v-if="!form.is_twitter_icon" class="upload_image mt-2">
                            <image-library v-model="form.twitter_icon" image-type="favicon" :is-avatar="false" />
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="capsule_elm">
                        <h5>Show Linkedin</h5>
                        <label for="showlinkedin" class="switch_option capsule_btn p-0">
                            <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_linkedin" id="showlinkedin" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div v-if="form.is_linkedin">
                        <div class="form_grp pt-2 mb-2">
                            <div class="group_item">
                                <div class="field_wpr">
                                    <Field autocomplete="off" type="text" name="linkedin_url" v-model="form.linkedin_url" placeholder="https://www.linkedin.com/onboardme" />
                                </div>
                                <ErrorMessage name="linkedin_url" class="text-danger" />
                            </div>
                        </div>
                        <div class="capsule_elm">
                            <h5>Use Default Linkedin Icon</h5>
                            <label for="linkedinicon" class="switch_option capsule_btn p-0">
                                <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_linkedin_icon" id="linkedinicon" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div v-if="!form.is_linkedin_icon" class="upload_image mt-2">
                            <image-library v-model="form.linkedin_icon" image-type="favicon" :is-avatar="false" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="capsule_elm">
                <h5>Use as Default</h5>
                <label :for="`usedefault-${uuid}`" class="switch_option capsule_btn">
                    <input type="checkbox" :true-value="1" :false-value="0" v-model="form.use_default" :id="`usedefault-${uuid}`" hidden>
                    <div><span></span></div>
                </label>
            </div>
            <div class="capsule_elm" v-if="form.use_default">
                <h5>Show default signature on all emails</h5>
                <label :for="`defaultsig-${uuid}`" class="switch_option capsule_btn">
                    <input type="checkbox" :true-value="1" :false-value="0" v-model="form.apply_to_all" :id="`defaultsig-${uuid}`" hidden>
                    <div><span></span></div>
                </label>
            </div>
            <div class="action_wpr mt-5">
                <button type="button" class="btn cancel_btn" @click="handleCancel()">Cancel</button>
                <button class="btn save_btn">Save Signature</button>
            </div>
        </Form>
    </div>
</template>

<script>
    import ImageLibrary from '@/components/image-library/ImageLibrary'
    import ColorPicker from '@/components/ColorPicker'
    import * as yup from 'yup'

    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { uuid } from 'vue-uuid'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Create Signature',

        data () {
            const schema = yup.object({
                signature_name: yup.string().required().label('The signature name'),
                email: yup.string().email().label('The email'),
                facebook_url: yup.string().url().label('The url'),
                ig_url: yup.string().url().label('The url'),
                yt_url: yup.string().url().label('The url'),
                linkedin_url: yup.string().url().label('The url'),
                website: yup.string().url().label('The url'),
            });

            return {
                form: {
                    signature_name: '',
                    bar_color: '#2c3e50',
                    bar_text_color: '#ffffff',
                    border_radius: '5',
                    full_name: '',
                    credentials: 'PHD, CSCS',
                    company_name: '',
                    slogan: 'Coach without boundaries',
                    author_image: '',
                    phone: '',
                    email: '',
                    website: '',
                    address: '',
                    link_color: '#2c3e50',
                    is_abbreviate: 0,
                    is_facebook: 0,
                    facebook_url: '',
                    is_facebook_icon: 0,
                    facebook_icon: '',
                    is_ig: 0,
                    ig_url: '',
                    is_ig_icon: 0,
                    ig_icon: '',
                    is_yt: 0,
                    yt_url: '',
                    is_yt_icon: 0,
                    yt_icon: '',
                    is_linkedin: 0,
                    linkedin_url: '',
                    is_linkedin_icon: 0,
                    linkedin_icon: '',
                    use_default: 0,
                    apply_to_all: 0,
                    is_twitter: 0,
                    twitter_url: '',
                    is_twitter_icon: 0,
                    twitter_icon: '',
                    top_padding: 20,
                    bottom_padding: 20,
                    left_padding: 20,
                    right_padding: 20,
                    top_margin: 50,
                    bottom_margin: 30,
                    left_margin: 0,
                    right_margin: 0,
                    linebar_height: 2,
                    linebar_width: 100,
                    linebar_mt: 10,
                    linebar_mb: 10,
                    social_mt: 10,
                    social_mb: 10,
                    linebar_color: '#2c3e50',
                    social_icon_color: '#59c7f9',
                    social_icon_position: 'under',
                    icon_color: '#2c3e50',
                    has_phone: 1,
                    has_email: 1,
                    has_address: 1,
                    has_website: 1,
                },
                schema,
                brandBar: false,
                bio: false,
                social: false,
                useDefault: false,
                suckerCanvas: null,
                suckerArea: [],
                isSucking: false,
                uuid: 'uuid-'+uuid.v4(),
                placeholder: {
                    signature_name: 'ex: Studio Signature',
                    full_name: 'Joe Trainer',
                    credentials: 'PhD, CSCS',
                    company_name: 'Superfit Coaching, LLC',
                    slogan: 'Coach without boundaries ',
                    phone: '+1(999)999-999',
                    email: 'joe@superfitcoaching.com',
                    website: 'superfitcoaching.com',
                    address: '123 Super Street Supertown, CO 12345',
                },
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            ColorPicker,
            ImageLibrary,
        },

        computed: mapState({
            user: state => state.authModule.user,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                storeSignature: 'signatureModule/storeSignature',
            }),

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;
                      params.setFieldError = setFieldError;

                vm.storeSignature(params).then((result) => {
                    if (result) {
                        vm.handleCancel();
                    }
                });
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    signature_name: '',
                    bar_color: '#2c3e50',
                    bar_text_color: '#ffffff',
                    border_radius: '5',
                    full_name: '',
                    credentials: 'PHD, CSCS',
                    company_name: '',
                    slogan: 'Coach without boundaries',
                    author_image: '',
                    phone: '',
                    email: '',
                    website: '',
                    address: '',
                    link_color: '#2c3e50',
                    is_abbreviate: 0,
                    is_facebook: 0,
                    facebook_url: '',
                    is_facebook_icon: 0,
                    facebook_icon: '',
                    is_ig: 0,
                    ig_url: '',
                    is_ig_icon: 0,
                    ig_icon: '',
                    is_yt: 0,
                    yt_url: '',
                    is_yt_icon: 0,
                    yt_icon: '',
                    is_linkedin: 0,
                    linkedin_url: '',
                    is_linkedin_icon: 0,
                    linkedin_icon: '',
                    use_default: 0,
                    apply_to_all: 0,
                    is_twitter: 0,
                    twitter_url: '',
                    is_twitter_icon: 0,
                    twitter_icon: '',
                    top_padding: 20,
                    bottom_padding: 20,
                    left_padding: 20,
                    right_padding: 20,
                    top_margin: 50,
                    bottom_margin: 30,
                    left_margin: 0,
                    right_margin: 0,
                    linebar_height: 2,
                    linebar_width: 100,
                    linebar_mt: 10,
                    linebar_mb: 10,
                    social_mt: 10,
                    social_mb: 10,
                    linebar_color: '#2c3e50',
                    social_icon_color: '#59c7f9',
                    social_icon_position: 'under',
                    icon_color: '#2c3e50',
                    has_phone: 1,
                    has_email: 1,
                    has_address: 1,
                    has_website: 1,
                };
            },

            handleCancel () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            }
        },

    }
</script>

<style scoped>
.signature_section{
    margin: 15px 0;
    background: #f9f9f9;
    border-radius: 8px;
    padding: 15px 20px 0 20px;
}
body .signature_section .action_wpr{
    margin: 0 -20px;
    padding: 20px;
}
</style>